* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background-color: #fcfcfc;
}

.admin-drawer {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin: 5px 0px;
  border-radius: 0px 10px 10px 0px;
  text-decoration: none;
  color: #000;
  width: 100%;
}
.admin-drawer:hover {
  background: linear-gradient(
    95.4deg,
    rgba(4, 130, 202, 0.8) 4.07%,
    #0081c5 91.47%
  );
  color: white;
}

.admin-drawer:hover svg {
  color: white;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  border-radius: 5px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
  outline: 0px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.isActive[aria-current="page"] {
  background: linear-gradient(
    95.4deg,
    rgba(4, 130, 202, 0.8) 4.07%,
    #0081c5 91.47%
  );
  color: white;
  transition: 0.5s;
}
.isActive svg {
  color: white;
}

.disabled-link {
  pointer-events: none;
}

.mycustom_tabs {
  overflow-x: auto;
}

.mycustom_tabs .Mui-selected {
  color: #5dc4ff;
  background-color: rgba(0, 0, 0, 0.02) !important;
  position: relative;
}
.mycustom_tabs .Mui-selected::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  background-color: #5dc4ff;
}
.MuiList-root.MuiMenu-list > li {
  min-height: auto !important;
}

.MuiTabPanel-root {
  padding: 0px !important;
}
.css-1d7n4hl-MuiInputBase-root-MuiTablePagination-select {
  margin-right: 10px !important;
}
.css-mjpk2e-MuiButtonBase-root-MuiStepButton-root {
  padding: 24px 10px !important;
}

@media only screen and (max-width: 900px) {
  th,
  td {
    white-space: nowrap;
  }
}

.editor-class * {
  margin: 0 !important;
  padding: 0 !important;
}

.editor-class {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
  min-height: 20vh;
}
.toolbar-class {
  border: 1px solid #ccc;
}

tr {
  white-space: nowrap;
}

.MuiTableContainer-root::-webkit-scrollbar {
  width: 1em;
  height: 10px;
  transition: 0.5s;
}

.MuiTableContainer-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: #5dc4ff9c;
  border-radius: 100vw;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
  background-color: #5dc4ff;
}
